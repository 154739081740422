<template>
  <div class="show-package">
    <loading v-if="isLoading" />
    <div v-else>
      <div v-if="data.courses.length > 0">
        <b-row class="academic-courses-header">
          <p class="academic-courses-title">
            {{ `${data.name} - ${$t("g.classrooms/allCourses/courses")}` }}
          </p>
          <hr class="border-2" />
        </b-row>
        <course-card-component
          :sub-package="true"
          :coursesData="data.courses"
          :edu_year="data.edu_year_id"
          @call-back="getPackageCourses"
          :permission="{ index: true, store: true, delete: true, update: true }"
        />
      </div>
      <div class="no-data" v-else>
        {{ $t("noCoursesInThisPackages") }}
      </div>
    </div>
  </div>
</template>
<script>
import CourseCardComponent from "@/components/classrooms/course/CourseCardComponent/index.vue";
import loading from "@/components/loading/loading.vue";
import { BRow } from "bootstrap-vue";

export default {
  name: "ShowPackage",
  components: { BRow, loading, CourseCardComponent },
  data() {
    return {
      isLoading: null,
      data: [],
    };
  },
  created() {
    this.getPackageCourses();
  },
  methods: {
    async getPackageCourses() {
      this.isLoading = true;
      try {
        const _id = this.$route.params.id;
        const response = await this.$http.get(`admin/packages/${_id}`);
        if (response.status === 200 || response.status === 201) {
          this.data = response.data.data;
        }
      } catch (error) {
        console.log(error);
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "./_index";
</style>
